<template>
  <section>
    <v-container>
      <v-row>
        <v-col md="12">
          <div class="headerBack">
            <h2>KYC Form</h2>
          </div>
        </v-col>
      </v-row>
      <div v-if="formLoading">
        <v-row>
          <v-col md="12" class="text-center h-full">
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </div>
      <div v-if="!formLoading">
        <v-row>
          <v-col md="12" v-if="user && !user.affiliationStatus">
            <v-alert outlined type="info" text class="text-center">
              {{ user.kycreason.verficationStatus }} :
              {{ user.kycreason.description }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" v-if="user && user.affiliationStatus">
            <v-alert
              outlined
              type="success"
              text
              class="text-left"
              v-if="user.kyc"
            >
              Your KYC has been verified.
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="user">
          <v-form class="full-width" ref="form">
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="First Name"
                outlined
                dense
                v-model="firstName"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="Last Name"
                outlined
                dense
                v-model="lastName"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="Email"
                outlined
                dense
                :disabled="user.kyc"
                v-model="email"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="Phone"
                outlined
                dense
                v-model="phone"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="Address"
                outlined
                dense
                v-model="address"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="City"
                outlined
                dense
                v-model="city"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="State"
                outlined
                dense
                v-model="state"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="Country"
                outlined
                dense
                v-model="country"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="Zip Code"
                outlined
                dense
                v-model="zip"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-menu
                ref="menu"
                v-model="menudob"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dob"
                    label="DoB"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="user.kyc"
                    :rules="[fieldRules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dob"
                  :active-picker.sync="activePickerdob"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="Id Issued By"
                outlined
                dense
                v-model="idIssuedBy"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-menu
                ref="menu"
                v-model="menuIssueDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="idIssuedDate"
                    label="Issue Date"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="user.kyc"
                    :rules="[fieldRules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="idIssuedDate"
                  :active-picker.sync="activePickerIssueDate"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="12" class="pt-0 pb-0" v-if="idType != 'National ID'">
              <v-menu
                ref="menu"
                v-model="menuidExpiry"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="idExpiry"
                    label="Expiry Date"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="user.kyc"
                    :rules="[fieldRules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="idExpiry"
                  :active-picker.sync="activePickeridExpiry"
                  :min="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-select
                label="ID Type"
                :items="idTypes"
                outlined
                dense
                v-model="idType"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-select>
            </v-col>
            <v-col md="12" class="pt-0 pb-0">
              <v-text-field
                label="ID Number"
                outlined
                dense
                v-model="idNumber"
                :disabled="user.kyc"
                :rules="[fieldRules.required]"
              ></v-text-field>
            </v-col>
            <v-row class="px-3">
              <v-col md="4" sm="12" class="pt-0 pb-0">
                <v-file-input
                  label="National ID Front"
                  outlined
                  dense
                  v-model="idFront"
                  v-if="
                    !idFront ||
                    user.kycreason.verficationStatus == 'Disapproved'
                  "
                  :disabled="user.kyc"
                  :rules="[fieldRules.required]"
                ></v-file-input>
                <img
                  v-if="idFront"
                  :src="idFront"
                  alt=""
                  width="100%"
                  class="mb-4"
                />
              </v-col>
              <v-col md="4" sm="12" class="pt-0 pb-0">
                <v-file-input
                  label="National ID Back"
                  outlined
                  dense
                  v-if="
                    !idBack || user.kycreason.verficationStatus == 'Disapproved'
                  "
                  v-model="idBack"
                  :disabled="user.kyc"
                  :rules="[fieldRules.required]"
                ></v-file-input>
                <img
                  v-if="idBack"
                  :src="idBack"
                  alt=""
                  width="100%"
                  class="mb-4"
                />
              </v-col>
              <v-col md="4" sm="12" class="pt-0 pb-0">
                <v-file-input
                  label="Passport Size Photo"
                  outlined
                  dense
                  v-if="
                    !passportSizePhoto ||
                    user.kycreason.verficationStatus == 'Disapproved'
                  "
                  v-model="passportSizePhoto"
                  :disabled="user.kyc"
                  :rules="[fieldRules.required]"
                ></v-file-input>
                <img
                  v-if="passportSizePhoto"
                  :src="passportSizePhoto"
                  alt=""
                  width="100%"
                  class="mb-4"
                />
              </v-col>
            </v-row>

            <v-col md="12" class="pt-0 pb-0">
              <v-btn
                class="primary"
                v-if="user && !user.kyc"
                :loading="loading"
                @click="submit"
                >Submit</v-btn
              >
            </v-col>
          </v-form>
        </v-row>
        <v-snackbar v-model="snackbar">
          {{ message }}
        </v-snackbar>
      </div>
    </v-container>

    <p class="infoMessage">
      If you need corrections or updates to the information you've provided,
      please contact us at
      <span class="mailColor">support@postaam.com</span>.
    </p>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "KYCForm",
  data: () => ({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    zip: undefined,
    dob: undefined,
    idType: undefined,
    idNumber: undefined,
    idFront: undefined,
    idBack: undefined,
    passportSizePhoto: undefined,
    idExpiry: undefined,
    idIssuedBy: undefined,
    idIssuedDate: undefined,
    idTypes: ["Passport", "Driver's License", "National ID"],
    user: undefined,
    valid: true,
    activePickerIssueDate: null,
    menuIssueDate: false,

    activePickeridExpiry: null,
    menuidExpiry: false,

    activePickerdob: null,
    menudob: false,
    snackbar: false,
    message: "",
    loading: false,
    fieldRules: {
      required: (value) => !!value || "Field is required.",
    },
    formLoading: true,
  }),
  methods: {
    ...mapActions(["getUsersProfile", "postKyc"]),
    async submit() {
      let formData = new FormData();
      if (this.$refs.form.validate()) {
        if (this.firstName) formData.append("firstName", this.firstName);
        if (this.lastName) formData.append("lastName", this.lastName);
        if (this.email) formData.append("email", this.email);
        if (this.phone) formData.append("phone", this.phone);
        if (this.address) formData.append("address", this.address);
        if (this.city) formData.append("city", this.city);
        if (this.state) formData.append("state", this.state);
        if (this.country) formData.append("country", this.country);
        if (this.zip) formData.append("zip", this.zip);
        if (this.dob) formData.append("dob", this.dob);
        if (this.idType) formData.append("idType", this.idType);
        if (this.idNumber) formData.append("idNumber", this.idNumber);
        if (this.idFront) formData.append("idFront", this.idFront);
        if (this.idBack) formData.append("idBack", this.idBack);
        if (this.passportSizePhoto)
          formData.append("passportSizePhoto", this.passportSizePhoto);
        if (this.idExpiry) formData.append("idExpiry", this.idExpiry);
        if (this.idIssuedBy) formData.append("idIssuedBy", this.idIssuedBy);
        if (this.idIssuedDate)
          formData.append("idIssuedDate", this.idIssuedDate);

        this.loading = true;
        await this.postKyc(formData);
        this.snackbar = true;
        this.message = this.allKyc.message;
        this.loading = false;
        this.user.kyc = true;
        location.reload();
      }
    },
  },
  computed: {
    ...mapGetters(["allUserSingle", "allKyc"]),
  },
  async created() {
    await this.getUsersProfile();
    this.user = this.allUserSingle.results;
    this.firstName = this.user.fullname.split(" ")[0];
    this.lastName =
      this.user.fullname.split(" ").length > 1
        ? this.user.fullname.split(" ")[1]
        : "";
    this.email = this.user.email;
    this.phone = this.user.phoneNumber;
    this.address = this.user.address;
    this.city = this.user.city;
    this.country = this.user.country;
    this.zip = this.user.zip;
    this.state = this.user.state;
    this.idIssuedDate = this.user.idIssuedDate.slice(0, 10);
    this.idExpiry = this.user.idExpiry.slice(0, 10);
    this.dob = this.user.dob.slice(0, 10);
    this.idNumber = this.user.idNumber;
    this.idIssuedBy = this.user.idIssuedBy;
    this.idType = this.user.idType;
    this.idFront = this.user.idFront;
    this.idBack = this.user.idBack;
    this.passportSizePhoto = this.user.passportSizePhoto;

    // console.log(this.user.kyc)
    this.user.kyc =
      this.user.kycreason.verficationStatus == "Disapproved"
        ? false
        : this.user.kyc;

    this.formLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.headerBack {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.infoMessage {
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
.mailColor {
  text-decoration: underline;
  font-style: italic;
}
.h-full {
  height: 85vh;
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>