<template>
  <section>
    <Kycform v-if="this.$route.query.name == 'kyc_verification'" />
    <v-container v-if="this.$route.query.name != 'kyc_verification'">
      <h1 class="text-center font-bold"> 
        {{ this.$route.query.name }}
        {{ this.$route.query.verificationStatus }}

      </h1>
    </v-container>
  </section>
</template>

<script>
import Kycform from "@/components/verification/Kycform.vue";
export default {
  name: "VerificationPage",
  components: {
    Kycform,
  },
};
</script>

<style>
</style>